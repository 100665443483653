import React from "react";
import Header from './header';
import Footer from './footer';




const DefaultLayout: React.FC<{ children: any }> = ({ children }) => {
  return (
    <div className="h-screen flex flex-col">
      <Header />
      <div className="flex-grow flex-wrap">

        {children}
      </div>

      <Footer />
    </div>
  );
}

export default DefaultLayout