import * as React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../pages/home";
import Events from "../pages/events";
import { Menu } from "../pages/menu";

import NotFoundPage from "../pages/404";
import { Contact } from "../pages/contact";
import { About } from "../pages/about";
import Gallery from "../pages/gallery";
import { Vacatures } from "../pages/vacatures";

export default function Router() {
    return (
        <Routes>
            <Route path="/" element={< Home />} />
            <Route path="/events" element={< Events />} />
            <Route path="/menu" element={< Menu />} />
            <Route path="/contact" element={< Contact />} />

            <Route path="/about" element={< About />} />

            <Route path='*' element={< NotFoundPage />} />
            <Route path='/gallery' element={< Gallery />} />
            <Route path='/vacatures' element={< Vacatures />} />

        </Routes>
    )
}