import { useNav } from "../lib/useNav";
import './section.css'
import { Celery } from '../components/icons/allergies/celery';
import { Crustacens } from '../components/icons/allergies/crustacens';
import { Egg } from '../components/icons/allergies/egg';
import { Fish } from '../components/icons/allergies/fish';
import { Lupine } from '../components/icons/allergies/lupine';
import { Milk } from '../components/icons/allergies/milk';
import { Mollusks } from '../components/icons/allergies/mollusks';
import { Mustard } from '../components/icons/allergies/mustard';
import { Nuts } from '../components/icons/allergies/nuts';
import { Pinda } from '../components/icons/allergies/pinda';
import { Sesamseed } from '../components/icons/allergies/sesamseed';
import { Soja } from '../components/icons/allergies/soja';
import { Sulfite } from '../components/icons/allergies/sulfite';
import { Gluten } from '../components/icons/allergies/gluten'
interface Item {
    item: any;
    showAllergies: boolean
}

const MenuItem: React.FC<Item> = ({ item, showAllergies }) => {

    const navref = useNav(item.label.replace(' ', '') + 'link')
    const getAllergyIcon = (name: string) => {
        switch (name) {
            case "celery":
                return <span title="celery" > <Celery className='w-6 h-6 fill-accent'></Celery></span>
            case "gluten":
                return <span title="celery" > <Gluten className='w-6 h-6 fill-accent'></Gluten></span>
            case "crustacens":
                return <span title="crustacens" ><Crustacens className='w-6 h-6 fill-accent'></Crustacens></span>
            case "egg":
                return <span title="egg" ><Egg className='w-6 h-6 fill-accent'></Egg></span>
            case "fish":
                return <span title="fish" ><Fish className='w-6 h-6 fill-accent'></Fish></span>
            case "lupine":
                return <span title="lupine" ><Lupine className='w-6 h-6 fill-accent'></Lupine></span>
            case "milk":
                return <span title="milk" ><Milk className='w-6 h-6 fill-accent'></Milk></span>
            case "mollusks":
                return <span title="mollusks" ><Mollusks className='w-6 h-6 fill-accent'></Mollusks></span>
            case "mustard":
                return <span title="mustard" > <Mustard className='w-6 h-6 fill-accent'></Mustard></span>
            case "nuts":
                return <span title="nuts" ><Nuts className='w-6 h-6 fill-accent'></Nuts></span>
            case "pinda":
                return <span title="pinda" ><Pinda className='w-6 h-6 fill-accent'></Pinda></span>
            case "sesamseed":
                return <span title="sesamseed" ><Sesamseed className='w-6 h-6 fill-accent'></Sesamseed></span>
            case "soja":
                return <span title="soja" ><Soja className='w-6 h-6 fill-accent'></Soja></span>
            case "sulfite":
                return <span title="sulfite" ><Sulfite className='w-6 h-6 fill-accent'></Sulfite></span>
        }
    }
    return (
        <section ref={navref} id={item.label.replace(' ', '')}>
            <img
                className=" object-cover w-[50vh] h-[50vh] object-right rounded-md"
                src={item.image}
                alt='unsplash-img'
            />
            <div>
                <div className={
                    'px-2.5 py-0 md:py-2.5 text-2xl font-bold leading-5 text-accent text-left'

                }>{item.label}</div>
                <div className={
                    'px-2.5 py-0 md:py-2.5 text-lg font-bold leading-5  text-left'

                }>{item.subtitle}</div>
                {item.timing && <h5 className={" text-lg top-0 text-center w-full font font-medium font underline "}>{"Verkrijgbaar " + item.timing}</h5>
                }
                <div className="grid grid-cols-1 md:grid-cols-3 p-0 m-0 pt-2">
                    {item.items.map((dish: {
                        label: string;
                        vegan: string;
                        price: string;
                        allergies: string[];
                    }) =>
                        <div className={`${showAllergies ? "p-0" : "p-1"} md:p-2`}>
                            <div className="flex md:block flex-row justify-between w-full items-center p-0 md:py-2">
                                <div className=' flex justify-center items-start md:items-center p-0 md:py-2'> <h3 className={"text-lg font-semibold font italic text-left"}>{dish.label}</h3> {dish.vegan && <h3 className={"text-md font-bold font italic text-green-800 text-center "}>( Vegan )</h3>}</div>
                                <h5 className={" text-sm font font-medium font italic p-0 md:py-2"}>{"€" + dish.price}</h5>
                            </div>
                            <h5 className={" text-sm font font-medium font text-gray-400 max-w-[150px] text-start p-0"}>{item.description}</h5>
                            {showAllergies && <div className='flex justify-start md:justify-center items-end  w-full flex-row p-0'  >
                                {showAllergies ? dish.allergies ? dish.allergies.map((allergy: string) => getAllergyIcon(allergy)) : <span title=" " ><div className="w-6 h-6 p-0"></div></span> : <span title=" " ><div className="w-6 h-6"></div></span>}
                            </div>}
                        </div>)}

                </div>
            </div>
        </section >
    )
}

export default MenuItem;
