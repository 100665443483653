import Link from '../../../components/ui/link';
import { Routes } from '../../../config/routes';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
export interface ClassnameProps {
  classname: string;

}
const headerLinks = [

  { href: Routes.home, icon: null, label: 'Hoofdpagina' },
  { href: Routes.about, icon: null, label: 'Over ons' },
  { href: Routes.menu, icon: null, label: 'Menu' },
  { href: Routes.events, icon: null, label: 'Evenementen' },
  { href: Routes.contact, icon: null, label: 'Contact' },
  { href: Routes.galery, icon: null, label: 'Foto Galerij' },
  { href: Routes.vacatures, icon: null, label: 'Vacatures' },


];

const StaticMenu: React.FC<ClassnameProps> = ({ classname }) => {
  const { t } = useTranslation('common');

  return (
    <>
      {headerLinks.map(({ href, label, icon }) => (
        <li key={`${href}${label}`}>
          <Link
            target={href}
            className={twMerge("flex items-center text-lg font-normal text-heading no-underline transition duration-200 hover:text-accent focus:text-accent", classname)}
          >
            {icon && <span className="ltr:mr-2 rtl:ml-2">{icon}</span>}
            {t(label)}
          </Link>
        </li>
      ))
      }
    </>
  );
};

export default StaticMenu;
