import React from 'react';
import DefaultLayout from '../components/layouts/default';
import { useHeader } from '../layouts/headers/header-atom';

const Gallery = () => {

    const { showHeader } = useHeader();
    showHeader()
    const images: any[] = [
        {
            id: 0,
            alt: "toog",
            url: "https://i0.wp.com/papillonkinrooi.be/wp-content/uploads/2021/10/IMG_2131.jpeg?resize=891%2C891&ssl=1"
        },
        {
            id: 0,
            alt: "indoor",
            url: "https://i0.wp.com/papillonkinrooi.be/wp-content/uploads/2021/10/IMG_2129-rotated.jpeg?resize=891%2C891&ssl=1"
        },
        {
            id: 0,
            alt: "tagliata",
            url: "https://i0.wp.com/papillonkinrooi.be/wp-content/uploads/2021/06/187094500_154330143370197_5564035403933789709_n.jpg?resize=891%2C891&ssl=1"
        },
        {
            id: 0,
            alt: "pasta",
            url: "https://i0.wp.com/papillonkinrooi.be/wp-content/uploads/2021/06/203124258_176607031142508_8023453298060011986_n.jpg?resize=891%2C891&ssl=1"
        },

    ]
    return (
        <DefaultLayout >
            <div className='w-full flex flex-col justify-center align-middle bg-white pt-20 px-0 md:px-20'>
                <div className='pt-16 flex justify-start flex-col items-center w-full' >

                    <h1
                        className={'text-2xl font-bold tracking-tight text-black lg:text-2xl xl:text-4xl '}
                    >
                        {"Galerij"}
                    </h1>

                </div>
                <div className='w-full flex justify-center align-middle bg-white px-8 sm:px-0 pt-8'>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4 ">
                        {images.map((image) => (
                            <div key={image.id} className="flex flex-col items-center">
                                <img src={image.url} alt={image.alt} className="rounded-lg shadow-lg mb-2" />
                                <p className="text-gray-700">{image.caption}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </DefaultLayout>
    );
};

export default Gallery;