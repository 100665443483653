import { Routes } from '../config/routes';
import Button from '../components/ui/button';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';

export default function NotFoundPage() {
  const { t } = useTranslation('common');
  const navigate = useNavigate();

  return (
    <div className="grid min-h-screen place-items-center p-4 sm:p-8">
      <div className="text-center">
        <p className="2xl:mb-4 text-sm uppercase tracking-widest text-body-dark sm:mb-5">
          {t('404-sub-heading')}
        </p>
        <p className="2xl:mb-4 text-sm uppercase tracking-widest text-body-dark sm:mb-5">
          {t('404-website-being-build')}
        </p>
        <h1 className="2xl:mb-4 text-8xl font-bold uppercase tracking-widest text-body-dark sm:mb-5">
          404
        </h1>

        <Button
          onClick={() => navigate(Routes.home)}
          className="inline-flex items-center text-bolder underline hover:text-body-dark hover:no-underline focus:outline-none sm:text-base"
        >
          {t('404-back-home')}
        </Button>
      </div>
    </div>
  );
}

