
import DefaultLayout from '../components/layouts/default';
import { siteSettings } from '../config/site';
import { useHeader } from '../layouts/headers/header-atom';

export const Contact = () => {
    const { showHeader } = useHeader();
    showHeader()

    return (
        <DefaultLayout >
            <div className='w-full flex flex-col justify-center align-middle bg-white pt-20 pb-0'>
                <div className='pt-16 flex justify-start flex-col items-center w-full' >

                    <h1
                        className={'text-2xl font-bold tracking-tight text-black lg:text-2xl xl:text-4xl '}
                    >
                        {"Contacteer ons"}
                    </h1>

                </div>
                <div className='md:grid md:grid-cols-2 grid-flow-col gap-0 w-full pt-10' >
                    <div className='flex justify-center md:justify-end items-center border-r-2 px-4 md:px-0 md:pr-20 md:py-20'>

                        <img alt={"bistro papillon kinrooi"} src="https://maps.googleapis.com/maps/api/staticmap?center=Bistro Papillon, Steyvershofstraat, Kinrooi belgie,CA&zoom=16&size=500x500&key=AIzaSyA5oEFlISS3hQdNx845B1KMCEbJx5iLLN0"></img>
                    </div>
                    <div className='flex justify-between items-start md:pl-20 px-4 md:px-0 py-6 md:py-20 flex-col'>
                        <div className='flex justify-between items-start flex-col pb-6 md:pb-0'>
                            <h4
                                className={'text-2xl font-bold tracking-tight text-black lg:text-xl xl:text-2xl '}
                            >
                                {"Bel ons"}
                            </h4>
                            <h6
                                className={'tracking-tight text-black py-2'}
                            >
                                {"Wil je reserveren of heb je een vraag? We zijn tijdens de openingsuren steeds bereikbaar via het nummer"}
                            </h6>
                            <h6
                                className={'tracking-tight text-accent '}
                            >
                                {siteSettings.footer.phone}
                            </h6>
                        </div>
                        <div className='flex justify-between items-start flex-col pb-6 md:pb-0'>
                            <h4
                                className={'text-2xl font-bold tracking-tight text-black lg:text-xl xl:text-2xl '}
                            >
                                {"Bezoek ons"}
                            </h4>
                            <h6
                                className={'tracking-tight text-black py-2'}
                            >
                                {"Je kan ons ook altijd terug vinden in onze bistro, dus twijfel niet om eens lekker te komen eten of te drinken."}
                            </h6>
                            <h6
                                className={'tracking-tight text-accent '}
                            >
                                {siteSettings.footer.address}
                            </h6>
                        </div>
                        <div className='flex justify-between items-start flex-col '>
                            <h4
                                className={'text-2xl font-bold tracking-tight text-black lg:text-xl xl:text-2xl '}
                            >
                                {"Mail ons"}
                            </h4>
                            <h6
                                className={'tracking-tight text-black py-2'}
                            >
                                {"Voor al jouw vragen kan je ook steeds een mail sturen, we proberen deze zo snel mogelijk te beantwoorden."}
                            </h6>
                            <h6
                                className={'tracking-tight text-accent '}
                            >
                                {siteSettings.footer.email}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>

        </DefaultLayout>
    );
};