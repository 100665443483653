export const Soja: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.0125 125" {...props}>
      <g
        transform="matrix(1.25,0,0,-1.25,0,125)"
        id="g10"><g
          transform="scale(0.1,0.1)"
          id="g12"><path
            id="path14"
            d="m 670.699,243.438 c 10.864,0 24.399,-0.848 37.781,0.156 50.043,3.75 57.618,17.273 36.915,61.328 -27.286,58.047 -71.125,96.926 -135.008,112.422 -32.051,7.773 -51.727,25.398 -61.285,56.894 -11.547,38.059 -34.477,68.731 -75.118,81.297 -33.168,10.266 -52.171,31.164 -62.054,63.125 -22.211,71.867 -83.297,103.508 -146.836,132.031 -10.715,4.817 -22.707,6.653 -20.977,-10.117 7.692,-74.629 -0.187,-154.527 70.438,-208.98 18.566,-14.321 28.304,-33.301 32.949,-55.617 13.707,-65.801 55.644,-104.832 121.734,-119.903 30.633,-6.98 58.492,-20.105 70.844,-48.738 24.836,-57.578 70.895,-70.59 130.617,-63.898" /><path
            id="path16"
            d="M 500.051,903.887 C 275.855,903.887 94.1055,722.141 94.1055,497.941 94.1055,273.75 275.855,92.0039 500.051,92.0039 c 224.191,0 405.941,181.7461 405.941,405.9371 0,224.2 -181.75,405.946 -405.941,405.946 l 0,0 z m 0,-38.059 c 202.847,0 367.883,-165.035 367.883,-367.887 0,-202.851 -165.036,-367.882 -367.883,-367.882 -202.856,0 -367.883,165.031 -367.883,367.882 0,202.852 165.027,367.887 367.883,367.887" /></g></g>
    </svg>
  );
};
