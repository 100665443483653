import StaticMenu from './menu/static-menu';
import { useHeader } from '../../layouts/headers/header-atom';
import cn from 'classnames';
import { useMemo } from 'react';
import { twMerge } from "tailwind-merge";
import React from 'react';
import { motion } from 'framer-motion';
import { NavbarIcon } from '../icons/navbar-icon';
import { drawerAtom } from '../../store/drawer-atom';

import { useTranslation } from "react-i18next";
import { useAtom } from 'jotai';
import Button from '../ui/button';
import { useModalAction } from '../ui/modal/modal.context';
const Header = () => {
  const { t } = useTranslation('common');
  const { show } = useHeader();
  const [_, setDrawerView] = useAtom(drawerAtom);
  if (0) console.log(_)
  const { openModal } = useModalAction()
  const showHeader = useMemo(
    () => show,
    [show]
  );
  function handleSidebar(view: string) {
    setDrawerView({ display: true, view });
  }
  return (
    <header
      className={'h-14 md:h-16 lg:h-22 absolute w-full max-w-screen overflow-hidden sm:overflow-visible bg-transparent'}
    >
      <div
        className={cn(
          'absolute w-full  lg:border-0 bg-transparent  top-0 sm:top-10 z-50 flex  h-14 transform-gpu items-center justify-center border-b border-border-200 bg-light px-4 py-5 shadow-sm transition-transform duration-300 md:h-16 lg:h-22 lg:px-8',
          {
            'lg:fixed bg-white lg:bg-white lg:shadow-none top-0 md:top-0 '
              :
              showHeader,
          }
        )}
      >
        {showHeader && <div className=" flex w-screen items-left lg:w-auto flex-col">
          <motion.button
            whileTap={{ scale: 0.88 }}
            onClick={() => handleSidebar('MAIN_MENU_VIEW')}
            className=" visible md:invisible flex h-full items-center justify-center p-2 focus:text-accent focus:outline-none absolute left-0 top-0"
          >
            <span className="sr-only">{t('text-burger-menu')}</span>
            <NavbarIcon />
          </motion.button>
          <img
            className="h-12 md:h-14 lg:h-18 object-contain"
            src={"/Logo-papillon.webp"}
            alt={'logo'}


          />        </div>}
        <div className=" invisible w-0 sm:visible flex sm:w-full items-left lg:w-auto flex-col  ">
          <h4 className={twMerge(cn("flex items-center text-2xl font-bold text-heading pr-20", {
            'text-black px-20 pr:0'
              :
              showHeader,
          }))}>
            Bistro Papillon
          </h4>
          <h6 className={twMerge(cn("flex items-center text-sm font-normal text-heading pr-20", {
            'text-black px-20'
              :
              showHeader,
          }))}>
            Food Drinks Enjoy Kinrooi
          </h6>

        </div>
        <ul className="hidden shrink-0 items-center space-x-7 rtl:space-x-reverse lg:flex 2xl:space-x-10">
          <StaticMenu classname={showHeader ? "text-black" : ""} />
          {showHeader && <div className="flex items-center space-x-4 rtl:space-x-reverse">
            <Button className='inline-flex h-12 shrink-0 items-center align-middle justify-center rounded border border-transparent bg-accent px-6 py-6 text-xl font-bold leading-none text-center transition duration-300 ease-in-out hover:bg-accent-hover focus:shadow focus:outline-none focus:ring-1 focus:ring-accent-700'
              onClick={() => openModal()}
            >{"Reserveer nu"}</Button>
          </div>}
        </ul>
      </div>
    </header >
  );
};

export default Header;
