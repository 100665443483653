export const Nuts: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.0125 125" {...props}>
      <g
        transform="matrix(1.25,0,0,-1.25,0,125)"
        id="g10"><g
          transform="scale(0.1,0.1)"
          id="g12"><path
            id="path14"
            d="m 614.766,405.918 c -0.993,-11.707 -2.106,-24.895 -8.133,-34.48 -45.348,-72.161 -118.547,-105.383 -199.699,-137.129 4.304,22.097 18.246,27.195 27.961,35.359 45.089,37.898 86.218,78.586 109.812,133.184 3.715,8.601 9.988,16.523 16.43,23.601 9.367,10.285 20.84,17.285 35.968,11.684 15.372,-5.696 18.809,-18.625 17.661,-32.219 z m 39.961,34.973 c -54.375,22.996 -108,47.656 -161.934,71.621 -55.051,24.492 -110.398,48.379 -165.004,73.758 -21.246,9.875 -32.242,5.605 -44.039,-14.5 -70.613,-120.375 -54.578,-232.805 25.59,-343.207 21.672,-29.833 36.906,-38.614 79.598,-37.458 148.722,4.032 229.093,84.911 283.937,205.333 10.465,22.976 4.855,34.722 -18.148,44.453" /><path
            id="path16"
            d="m 731.109,535.508 c -0.789,-0.664 -4.035,-5.645 -7.832,-6.082 -7.054,-0.801 -10.91,5.469 -12.398,11.172 -10.25,39.097 -40.113,61.336 -72.836,81.621 -18.902,11.719 -28.516,28.457 -14.156,48.289 13.754,19.004 32.543,12.082 49.511,3.039 47.875,-25.5 78.594,-95.52 57.711,-138.039 z m 18.2,99.035 c -17.168,32.242 -45.907,52.703 -74.375,73.543 -22.715,16.633 -34.954,36.465 -28.047,64.773 2.793,11.457 7.855,23.594 -8.524,30.52 -14.883,6.301 -23.129,-1.668 -30.765,-12.024 -16.489,-22.332 -39.727,-26.519 -65.778,-22.617 -28.027,4.223 -55.922,9.387 -84.043,12.856 -42.097,5.191 -78.629,-6.606 -105.843,-38.852 -17.09,-20.254 -31.567,-41.746 -38.204,-68.633 -11.929,-48.328 -13.511,-48.023 33.887,-69.175 51.578,-23.02 103.207,-45.918 154.824,-68.86 54.977,-24.441 109.946,-48.894 164.922,-73.34 1.117,-0.5 2.328,-0.871 3.36,-1.504 24.886,-15.402 43.203,-6.464 60.785,13.243 41.195,46.179 47.539,104.199 17.801,160.07" /><path
            id="path18"
            d="M 503.355,901.621 C 280.609,901.621 100.043,721.055 100.043,498.313 100.043,275.566 280.609,95 503.355,95 726.094,95 906.66,275.566 906.66,498.313 c 0,222.742 -180.566,403.308 -403.305,403.308 l 0,0 z m 0,-37.812 c 201.532,0 365.497,-163.965 365.497,-365.496 0,-201.536 -163.965,-365.5 -365.497,-365.5 -201.539,0 -365.5,163.964 -365.5,365.5 0,201.531 163.961,365.496 365.5,365.496" /></g></g>

    </svg>
  );
};
