export const Mollusks: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.0125 125" {...props}>
      <g
        transform="matrix(1.25,0,0,-1.25,0,125)"
        id="g10"><g
          transform="scale(0.1,0.1)"
          id="g12"><path
            id="path14"
            d="M 448.227,292.578 C 560.945,323.73 666.492,368.684 763.176,430.703 674.109,354.023 568.809,308.594 448.227,292.578 Z M 396.98,306.27 c 118.036,71.054 243.188,133 340.907,230.07 C 650.355,426.223 525.57,363.809 396.98,306.27 Z m -30.578,21.398 C 465.703,436.582 570.707,541.125 661.879,656.68 584.855,530.715 478.391,427.059 366.402,327.668 Z M 561.121,717.441 C 487.535,600.969 415.414,483.777 351.711,362.297 398.262,492.258 472.383,608.516 561.121,717.441 Z M 334.449,401.016 C 327.086,542.645 397.195,658.328 475.582,771.23 415.113,652.313 342.984,537.266 334.449,401.016 Z m -32.75,-2.395 c -11.633,119.414 0.199,236.313 46.156,349.348 C 320.328,632.859 310.211,515.891 301.699,398.621 Z M 793.871,545.293 C 766.91,691.785 628.699,797.305 462.305,797.766 392.027,800.293 326.113,777.742 269.535,730.996 237.266,704.336 217.012,671.316 211,629.68 c -7.543,-52.258 3.668,-101.575 19.668,-150.586 5.332,-16.352 7.812,-30.649 -6.773,-45.285 -30.758,-30.887 -29.215,-42.746 6.496,-68 11.265,-7.965 23.625,-14.918 36.351,-20.496 28.731,-12.586 55.629,-26.692 73.602,-53.165 9.136,-13.476 24.883,-16.14 40.375,-15.234 21.043,1.231 36.629,-6.633 49.945,-21.711 10.375,-11.758 22.016,-22.574 33.774,-33.113 33.765,-30.281 55.914,-26.692 71.914,14.426 8.992,23.093 23.222,34.293 47.949,36.902 12.894,1.355 25.383,6.035 38.164,8.73 146.644,30.899 190.152,161.321 171.406,263.145" /><path
            id="path16"
            d="M 500.051,910.051 C 273.598,910.051 90.0234,726.477 90.0234,500.027 90.0234,273.574 273.598,90 500.051,90 726.5,90 910.074,273.574 910.074,500.027 c 0,226.45 -183.574,410.024 -410.023,410.024 l 0,0 z m 0,-38.442 c 204.89,0 371.586,-166.687 371.586,-371.582 0,-204.89 -166.696,-371.589 -371.586,-371.589 -204.895,0 -371.582,166.699 -371.582,371.589 0,204.895 166.687,371.582 371.582,371.582" /></g></g>
    </svg>
  );
};
