import React from 'react';
import NavLink from './NavLink';
import './Nav.css';

const Nav = ({ navLinks }) => {
    return (
        <nav id='menunav' className='hidden md:block  pb-24' >
            {navLinks.map(({ label, navLinkId, scrollToId }, idx) => (
                <NavLink label={label} key={idx} navLinkId={navLinkId} scrollToId={scrollToId} />
            ))}
        </nav>
    );
};

export default Nav;