export const Sesamseed: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.0125 125" {...props}>
      <g
        transform="matrix(1.25,0,0,-1.25,0,125)"
        id="g10"><g
          transform="scale(0.1,0.1)"
          id="g12"><path
            id="path14"
            d="m 674.145,357.617 c -35.993,26.809 -53.165,88.02 -30.707,115 21.113,25.371 45.761,18.086 68.554,1.77 34.442,-24.668 51.168,-89.035 31.09,-115.981 -14.762,-19.801 -42.977,-20.125 -68.937,-0.789" /><path
            id="path16"
            d="m 349.746,312.285 c -24.453,27.488 -57.418,56.223 -30.074,93.086 25.68,34.617 61.965,14.149 87.652,-5.558 29.309,-22.489 57.531,-54.864 30.996,-91.344 -25.625,-35.215 -59.277,-9.426 -88.574,3.816" /><path
            id="path18"
            d="m 305.199,615.762 c 39.551,-29.942 55.969,-91.399 32.504,-117.199 -22.144,-24.352 -46.863,-17.379 -69.019,-0.196 -35.52,27.551 -49.286,91.926 -25.446,116.235 21.375,21.785 43.457,12.976 61.961,1.16" /><path
            id="path20"
            d="m 483.578,523.73 c 34.434,-26.32 50.91,-91.394 28.43,-116.55 -20.246,-22.66 -43.508,-16.164 -64.817,-1.567 -37.078,25.41 -54.187,87.383 -32.894,115.039 15.922,20.688 44.582,21.953 69.281,3.078" /><path
            id="path22"
            d="m 546.875,522.156 c -37.844,28.203 -52.598,88.891 -29.52,116.125 19.625,23.164 42.278,18.145 63.606,3.449 35.262,-24.308 52.754,-87.792 31.859,-114.64 -20.672,-26.555 -44.465,-17.91 -65.945,-4.934" /><path
            id="path24"
            d="m 697.063,539.758 c -38.434,28.594 -55.477,91.265 -33.938,117.91 21.508,26.617 45.188,17.305 67.539,0.684 37.422,-27.825 50.996,-86.719 26.481,-113.95 -20.309,-22.547 -42.047,-16.504 -60.082,-4.644" /><path
            id="path26"
            d="m 515.742,286.738 c -19.547,16.66 -30.769,37.317 -8.558,62.45 22.046,24.96 84.281,24.074 118.277,-2.086 22.078,-16.997 35.844,-37.844 15.363,-64.172 -21.445,-27.586 -86.758,-26.016 -125.082,3.808" /><path
            id="path28"
            d="m 395.762,718.184 c 37.367,-29.961 53.625,-90.528 31.855,-114.832 -22.328,-24.934 -46.531,-16.055 -68.883,1.503 -34.32,26.961 -50.254,87.059 -28.32,111.563 22.375,24.969 46.297,15.562 65.348,1.766" /><path
            id="path30"
            d="m 503.227,905.461 c -223.575,0 -404.8168,-181.242 -404.8168,-404.816 0,-223.582 181.2418,-404.8247 404.8168,-404.8247 223.582,0 404.824,181.2427 404.824,404.8247 0,223.574 -181.242,404.816 -404.824,404.816 l 0,0 z m 0,-37.949 c 202.296,0 366.871,-164.582 366.871,-366.867 0,-202.293 -164.575,-366.875 -366.871,-366.875 -202.289,0 -366.864,164.582 -366.864,366.875 0,202.285 164.575,366.867 366.864,366.867" /></g></g>
    </svg>
  );
};
