
interface DrinkState {
    drinkCategory: any;
}

const DrinkItem: React.FC<DrinkState> = ({ drinkCategory }) => {


    return (
        <div className="px-5 md:px-20 py-4 flex justify-center flex-col items-center w-full">
            <div className={
                'py-2.5 text-2xl font-bold leading-5 text-accent text-left'

            }>{drinkCategory.label}</div>

            <div className="flex justify-center flex-col item-start w-full">
                {drinkCategory.items.map((drink: {
                    label: string;
                    price: string;
                }) =>
                    <div className='flex justify-between '>
                        <h3 className={"text-lg font-semibold font italic text-start"}>{drink.label}</h3>
                        <h5 className={" text-md font font-medium font italic text-start pl-8 "}>{"€" + drink.price.toFixed(2)}</h5>
                    </div>)}

            </div>
        </div>
    )
}

export default DrinkItem;
