export const Egg: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.0125 125" {...props}>
      <g
        transform="matrix(1.25,0,0,-1.25,0,125)"
        id="g10"><g
          transform="scale(0.1,0.1)"
          id="g12"><path
            id="path14"
            d="m 722.77,453.457 c 0,-150.789 -101.207,-273.035 -226.051,-273.035 -124.844,0 -226.043,122.246 -226.043,273.035 0,150.801 101.199,366.121 226.043,366.121 46.816,0 90.308,-30.269 126.386,-76.07 0.786,-1.008 -16.292,-21.086 -16.292,-21.086 l 3.718,-15.508 -15.297,2.852 -2.714,-5.996 -13.411,1.105 4.161,-15.355 -5.297,-4.852 3.414,-11.738 -10.442,1.836 -13.332,-21.375 c 0,0 5.828,2.109 13.008,7.672 2.305,1.789 7.524,6.007 7.524,6.007 l 14.089,-4.961 -4.613,12.664 4.508,3.625 1.039,13.411 11.676,-3.286 3.625,2.872 15.297,-3.258 -1.243,15.105 c 0,0 18.762,14.961 19.547,13.75 49.336,-75.652 80.7,-179.59 80.7,-263.535" /><path
            id="path16"
            d="M 496.719,907.996 C 271.387,907.996 88.7227,725.332 88.7227,500 88.7227,274.668 271.387,92.0039 496.719,92.0039 c 225.332,0 408.004,182.6641 408.004,407.9961 0,225.332 -182.672,407.996 -408.004,407.996 l 0,0 z m 0,-38.25 c 203.883,0 369.75,-165.867 369.75,-369.746 0,-203.879 -165.867,-369.746 -369.75,-369.746 -203.883,0 -369.75,165.867 -369.75,369.746 0,203.879 165.867,369.746 369.75,369.746" /></g></g>
    </svg>
  );
};
