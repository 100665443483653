import classNames from 'classnames';
import { Link as RouterLink } from "react-router-dom";

export interface LinkProps {
  className?: string;
  title?: string;
  target?: string;
  variant?: 'button';
}

const Link: React.FC<LinkProps & { className?: string; title?: string, children: React.ReactNode }> = ({
  children,
  variant,
  title,
  target,
  className,
  ...props
}) => {
  return (
    (<RouterLink
      to={target + ""}
      {...props}
      className={classNames(
        {
          "inline-flex items-center justify-center flex-shrink-0 font-semibold leading-none rounded outline-none px-3 py-0 h-9 text-lg bg-accent text-light border border-transparent hover:bg-accent-hover transition duration-300 ease-in-out focus:outline-none focus:shadow focus:ring-1 focus:ring-accent-700'": variant === 'button',
        },
        className
      )}
      title={title}>

      {children}

    </RouterLink>)
  );
};

export default Link;
