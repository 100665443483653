import { ArrowNext, ArrowPrev } from '../icons';
import { Swiper, SwiperSlide, Navigation } from '../ui/slider';
import Button from '../ui/button';
import { useNavigate } from 'react-router';
import React from 'react';
import { useTranslation } from 'react-i18next';

const offerSliderBreakpoints = {
    320: {
        slidesPerView: 1,
        spaceBetween: 0,
    },
    580: {
        slidesPerView: 2,
        spaceBetween: 0,
    },
    1024: {
        slidesPerView: 3,
        spaceBetween: 0,
    },

};

export default function Sliders({ slides }: { slides: any[] }) {
    const { t } = useTranslation();
    const navigate = useNavigate()
    return (
        <div className="px-6 py-5 bg-white w-full">
            <div className="relative bg-white h-[555px] w-full">
                <Swiper
                    className="h-[555px] w-full"
                    id="offer"
                    //TODO: need discussion
                    // loop={true}
                    breakpoints={offerSliderBreakpoints}
                    modules={[Navigation]}
                    navigation={{
                        nextEl: '.next',
                        prevEl: '.prev',
                    }}
                >
                    {slides?.map((d, index) => (
                        <SwiperSlide key={'slide-' + index} className="relative bg-black h-[555px] w-full">
                            <div
                                className="absolute w-full h-[555px] flex flex-col z-10 justify-center items-center"
                            >
                                <p className={'text-lg font-bold tracking-tight text-white max-w-4xl pt-5 text-center'}>
                                    {d.title}
                                </p>
                                <p className={'text-lg font-normal tracking-tight text-white max-w-4xl text-center'}>
                                    {d.description}
                                </p>

                                <Button className='my-10' onClick={() => navigate(d.link)}
                                >{'Meer info'}</Button>
                            </div>
                            <img
                                className={' opacity-70 h-[555px] object-cover w-full ' + d.className}
                                src={d.image}
                                alt={d.title}

                            />
                        </SwiperSlide>
                    ))}
                </Swiper>

                <div
                    className="absolute z-10 flex items-center justify-center w-8 h-8 -mt-4 transition-all duration-200  rounded-full  cursor-pointer prev top-2/4 left-4 md:-mt-5 md:w-9 md:h-9 bg-accent  text-heading hover:bg-accent-100 "
                    role="button"
                >
                    <span className="sr-only">{t('text-previous')}</span>
                    <ArrowPrev width={18} height={18} />
                </div>
                <div
                    className="absolute z-10 flex items-center justify-center w-8 h-8 -mt-4 transition-all duration-200  rounded-full  cursor-pointer next top-2/4 right-4 md:-mt-5 md:w-9 md:h-9 bg-accent  text-heading hover:bg-accent-100 "
                    role="button"
                >
                    <span className="sr-only">{t('text-next')}</span>
                    <ArrowNext width={18} height={18} />
                </div>
            </div>
        </div>
    );
}
