export const Milk: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.0125 125" {...props}>
      <g
        transform="matrix(1.25,0,0,-1.25,0,125)"
        id="g10"><g
          transform="scale(0.1,0.1)"
          id="g12"><path
            id="path14"
            d="m 409.434,627.383 c -47.661,-34.61 -115.141,0 -115.141,0 0,0 35.062,74.902 64.789,108.933 l 104.711,0 c 29.684,-34.031 64.793,-108.933 64.793,-108.933 0,0 -71.535,34.582 -119.152,0 z m 137.238,7.949 c -1.453,3.066 -30.711,65.215 -59.645,103.391 6.45,3.914 10.871,12.332 10.871,22.129 0,13.523 -8.488,24.5 -18.96,24.5 l -135.028,0 c -10.472,0 -18.933,-10.977 -18.933,-24.5 0,-9.797 4.41,-18.215 10.859,-22.129 -28.949,-38.176 -58.195,-100.325 -59.645,-103.391 l -1.765,-3.809 0,-4.14 0,-347.344 c 0,-36.016 30.293,-65.391 67.476,-65.391 l 139.036,0 c 37.238,0 67.542,29.375 67.542,65.391 l 0,347.344 0,4.172 -1.808,3.777" /><path
            id="path16"
            d="m 642.441,373.992 c -19.851,-13.015 -69.175,0.305 -69.175,0.305 l -3.84,48.613 143.383,0 -3.817,-49.18 c 0,0 -46.687,13.34 -66.551,0.262 z m -85.839,60.481 16.773,-214.051 135.547,0 16.754,214.051 -169.074,0" /><path
            id="path18"
            d="M 500.051,907.996 C 274.719,907.996 92.0508,725.332 92.0508,500 92.0508,274.668 274.719,92.0039 500.051,92.0039 c 225.332,0 408,182.6641 408,407.9961 0,225.332 -182.668,407.996 -408,407.996 l 0,0 z m 0,-38.25 c 203.879,0 369.75,-165.867 369.75,-369.746 0,-203.879 -165.871,-369.746 -369.75,-369.746 -203.883,0 -369.754,165.867 -369.754,369.746 0,203.879 165.871,369.746 369.754,369.746" /></g></g>
    </svg>
  );
};
