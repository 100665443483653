import './App.css';
import './assets/css/main.css';
import ScrollToTop from './lib/scrollToTop';
import ManagedDrawer from './components/ui/drawer/managed-drawer';
import ManagedModal from './components/ui/modal/managed-modal';
import { ModalProvider } from './components/ui/modal/modal.context';

import Router from "./routes"
function App() {
  return (
    <div className="App">
      <ModalProvider>
        <div className=" min-h-screen flex flex-col">

          <ScrollToTop></ScrollToTop>

          <Router></Router>

          <ManagedDrawer />
          <ManagedModal />
        </div>

      </ModalProvider>

    </div>
  );
}

export default App;
