import MobileMainMenu from '../../layouts/mobile-menu/mobile-main-menu';
import { drawerAtom } from '../../../store/drawer-atom';
import { useAtom } from 'jotai';
import Drawer from './drawer';

export default function ManagedDrawer() {
  const [{ display }, setDrawerState] = useAtom(drawerAtom);
  return (
    <Drawer
      open={display}
      onClose={() => setDrawerState({ display: false, view: '' })}
      variant='left'
    >

      <MobileMainMenu></MobileMainMenu>

    </Drawer>
  );
}
