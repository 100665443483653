export const Fish: React.FC<React.SVGAttributes<{}>> = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.0125 125" {...props}>
      <g
        transform="matrix(1.25,0,0,-1.25,0,125)"
        id="g10"><g
          transform="scale(0.1,0.1)"
          id="g12"><path
            id="path14"
            d="m 282.883,483.672 c -15.996,0 -29.028,12.351 -29.028,27.566 0,15.227 13.032,27.563 29.028,27.563 16.023,0 29.035,-12.336 29.035,-27.563 0,-15.215 -13.012,-27.566 -29.035,-27.566 z m 70.535,161.687 C 237.926,596.816 162.172,493.621 162.172,493.621 c 0.617,-0.66 51.094,-59.656 104.406,-46.141 2.832,0.711 2.797,-6.984 1.277,-9.367 -3.261,-5.117 -11.136,-5.23 -17.269,-5.23 -32.477,0 -66.91,26.172 -84.438,41.707 24.809,-29.473 91.875,-101.953 179.051,-141.32 65.071,105.363 18.387,277.98 8.219,312.089" /><path
            id="path16"
            d="m 728.059,527.598 c -22.586,23.64 -55.125,54.011 -94.536,80.476 -8.039,5.403 -11.839,20.371 -46.253,46.059 -54.11,40.371 -57.012,36.59 -107.137,38.933 -3.664,0.176 -17.156,-24.882 -20.848,-24.882 -32.586,0 -63.98,-6.883 -93.422,-17.903 9.973,-32.144 60.157,-209.656 -8.363,-322.234 26.152,-10.598 53.938,-18.035 82.816,-20.352 6.278,-0.507 12.598,-0.769 18.969,-0.769 9.699,0 19.293,0.601 28.762,1.746 15.719,1.887 31.09,5.254 46.016,9.766 90.648,27.421 165.035,97.355 202.582,138.164 0,0 67.062,-94.903 91.359,-83.758 26.351,12.078 -7.789,69.453 -10.781,127.527 -2.864,55.535 26.386,111.887 8.816,122.883 -23.234,14.551 -97.98,-95.656 -97.98,-95.656" /><path
            id="path18"
            d="M 500.051,907.996 C 274.719,907.996 92.0508,725.332 92.0508,500 92.0508,274.668 274.719,92.0039 500.051,92.0039 c 225.332,0 407.996,182.6641 407.996,407.9961 0,225.332 -182.664,407.996 -407.996,407.996 l 0,0 z m 0,-38.25 c 203.879,0 369.75,-165.867 369.75,-369.746 0,-203.879 -165.871,-369.746 -369.75,-369.746 -203.883,0 -369.754,165.867 -369.754,369.746 0,203.879 165.871,369.746 369.754,369.746" /></g></g>
    </svg>
  );
};
