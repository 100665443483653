import { Routes } from '../../../config/routes';
import DrawerWrapper from '../../ui/drawer/drawer-wrapper';
import { useAtom } from 'jotai';
import { drawerAtom } from '../../../store/drawer-atom';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const headerLinks = [
  { href: Routes.home, icon: null, label: 'Hoofdpagina' },
  { href: Routes.about, icon: null, label: 'Over ons' },
  { href: Routes.menu, icon: null, label: 'Menu' },
  { href: Routes.events, icon: null, label: 'Evenementen' },
  { href: Routes.contact, icon: null, label: 'Contact' },
  { href: Routes.galery, icon: null, label: 'Foto Galerij' },

];

export default function MobileMainMenu() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [_, closeSidebar] = useAtom(drawerAtom);
  if (0) console.log(_)

  function handleClick(path: string) {
    navigate(path);
    closeSidebar({ display: false, view: '' });
  }

  return (
    <DrawerWrapper>
      <ul className="flex-grow h-screen">
        {headerLinks.map(({ href, label }) => (
          <li key={`${href}${label}`}>
            <button
              onClick={() => handleClick(href)}
              className="flex cursor-pointer items-center py-3 px-5 text-sm font-semibold capitalize text-black transition duration-200 hover:text-accent md:px-8"
            >
              {t(label)}
            </button>
          </li>
        ))}
      </ul>
    </DrawerWrapper>
  );
}
