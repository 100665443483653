
import DefaultLayout from '../components/layouts/default';
import { useHeader } from '../layouts/headers/header-atom';

export const About = () => {
    const { showHeader } = useHeader();
    showHeader()

    return (
        <DefaultLayout >
            <div className='w-full flex flex-col justify-center align-middle bg-white pt-20 pb-0'>
                <div className='pt-16 flex justify-start flex-col items-center w-full' >

                    <h1
                        className={'text-2xl font-bold tracking-tight text-black lg:text-2xl xl:text-4xl '}
                    >
                        {"Welkom bij Bert en Kelly"}
                    </h1>

                </div>
                <div className='w-full flex justify-center align-middle bg-white px-8 sm:px-0'>
                    <div className='pt-16 flex justify-center flex-col items-center' >



                        <p className={'text-md font-normal tracking-tight text-black max-w-4xl py-5 text-center'}>
                            {"Graag nodigen wij jullie het gehele jaar door uit bij ons in de bistro. Hier is iedereen welkom, van kampeerder tot woonachtige in en rond de streek. Wij bieden een zeer uitgebreide kaart aan voor jong en oud. Met onze buitenspeeltuin, zwemvijver en binnen speelhoek kunnen de kleinste onder ons zich na het eten steeds vermaken terwijl u nog van een heerlijk dessert of drankje geniet. Bekijk zeker ook onze allergenenlijst voor meer informatie over onze gerechten. Reserveer nu via bistro@papillonkinrooi.be of neem telefonisch contact op +32 456 269401. Tot snel!"}
                        </p>
                    </div>
                </div>
            </div>

        </DefaultLayout>
    );
};