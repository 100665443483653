import { atom, useAtom } from 'jotai';

const headerAtom = atom(false);

export function useHeader() {
  const [header, setHeader] = useAtom(headerAtom);
  return {
    show: header,
    toggle: () => setHeader(!header),
    showHeader: () => setHeader(true),
    hideHeader: () => setHeader(false),
  };
}
