import { useEffect } from 'react';


export default function TableBookerReservation() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = "https://reservations.tablebooker.com/tbkr-widget-import.min.js";
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
        }
    }, []);
    return (
        <div className="flex h-full min-h-screen w-screen flex-col justify-center bg-light py-6 px-5 sm:p-8 md:h-auto md:min-h-0 md:max-w-[480px] md:rounded-xl transition-height duration-500 ease-in-out
        ">
            <div className="flex justify-center">
                <img
                    className="h-12 md:h-14 lg:h-18 object-contain"
                    src={"/Logo-papillon.webp"}
                    alt={'logo'}
                />
            </div>

            <div className="flex justify-center pt-4  max-height: 500px;
    transition: max-height 0.25s ease-in;">
                <tbkr-bm-widget restaurant-id="51388418" source="website" use-modal="0" lang="nl" theme="light" primary-color="#ddb87b"></tbkr-bm-widget>
            </div>
        </div>
    );
}
