import Modal from './modal';
import TableBookerReservation from '../../table-booker/tableBookerReservation';
import { useModalAction, useModalState } from './modal.context';



const ManagedModal = () => {
  const { isOpen } = useModalState();
  const { closeModal } = useModalAction();



  return (
    <Modal open={isOpen} onClose={closeModal}>
      <TableBookerReservation />
    </Modal>
  );
};

export default ManagedModal;
