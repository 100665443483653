
import DefaultLayout from '../components/layouts/default';
import { siteSettings } from '../config/site';
import { useHeader } from '../layouts/headers/header-atom';

export const Vacatures = () => {
    const { showHeader } = useHeader();
    showHeader()

    return (
        <DefaultLayout >
            <div className='w-full flex flex-col justify-center align-middle bg-white pt-20 pb-0'>
                <div className='pt-16 flex justify-start flex-col items-center w-full' >

                    <h1
                        className={'text-2xl font-bold tracking-tight text-black lg:text-2xl xl:text-4xl '}
                    >
                        {"Vacatures"}
                    </h1>

                </div>
                <div className='md:grid md:grid-cols-2 grid-flow-col gap-0 w-full pt-10' >
                    <div className='flex justify-center md:justify-end items-center border-r-2 px-4 md:px-0 md:pr-20 md:py-20'>

                        <img alt={"bistro papillon kinrooi"} src="4300df54-c94a-11eb-8658-0a9cc9cb8194.jpg"></img>
                    </div>
                    <div className='flex justify-center items-start md:pl-40 px-4 md:px-0 py-6 md:py-20 flex-col'>
                        <div className='flex justify-center h-full items-start flex-col pb-6 md:pb-0'>
                            <h4
                                className={'text-xl font-bold tracking-tight text-black lg:text-xl xl:text-2xl '}
                            >
                                {"Werken bij ons"}
                            </h4>

                            <p
                                className={'tracking-tight text-black py-2 text-left max-w-lg'}
                            >
                                Ons team is steeds op zoek naar enthousiaste collega’s!

                                We zijn nog volop op zoek naar helpende handen voor zowel poets, keuken, zaal, animatie en receptie.

                                Mooie verloning, leuke collega’s, vakantiesfeer om in te werken.

                                Zowel met als zonder ervaring.

                                Flexi-student-vast-extra.<br /><br />

                            </p>
                            <p
                                className={'tracking-tight text-bold py-2 text-left max-w-lg text-accent text-lg'}
                            >
                                Voor meer info contacteer ons:<br />
                                Tel:+32 456 269401<br />
                                Mail:bistro@papillonkinrooi.be<br />

                            </p>

                        </div >


                    </div >
                </div >
            </div >

        </DefaultLayout >
    );
};