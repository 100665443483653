import { useAtom } from 'jotai';
import { drawerAtom } from '../../../store/drawer-atom';
import { CloseIcon } from '../../icons/close-icon';
import { useTranslation } from 'react-i18next';

const DrawerWrapper: React.FC<{ children: any }> = ({ children }) => {
  const { t } = useTranslation();
  const [show, closeSidebar] = useAtom(drawerAtom);
  if (0) console.log(show)
  return (
    <div className="flex h-full flex-col w-screen">
      <div className="fixed w-screen top-0 z-20 mb-4 flex  max-w-md items-center justify-between border-b border-border-200 border-opacity-75 bg-white p-5 md:mb-6">
        <img
          className="w-24 object-contain"
          src={"/Logo-papillon.webp"}
          alt={'logo'}


        />
        <button
          onClick={() => closeSidebar({ display: false, view: '' })}
          className="flex h-7 w-7 items-center justify-center rounded-full bg-gray-200 text-body transition-all duration-200 hover:bg-accent hover:text-light focus:bg-accent focus:text-light focus:outline-none"
        >
          <span className="sr-only">{t('text-close')}</span>
          <CloseIcon className="h-2.5 w-2.5" />
        </button>
      </div>
      <div className="pt-20">{children}</div>
    </div >
  );
};

export default DrawerWrapper;
