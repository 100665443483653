export const Gluten: React.FC<React.SVGAttributes<{}>> = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 125.0125 125" {...props}>
            <g
                transform="matrix(1.25,0,0,-1.25,0,125)"
                id="g10"><g
                    transform="scale(0.1,0.1)"
                    id="g12"><path
                        id="path14"
                        d="m 501.465,652.285 c 0.625,-10.344 1.012,-20.574 1.137,-30.715 -9.028,10.559 -18.93,20.41 -23.348,33.696 8.031,-2.063 15.453,-3.02 22.211,-2.981 z m -19.387,-322.59 c 12.61,27.063 23.66,54.692 32.758,83.032 6.879,-6.973 17.519,-11.055 30.965,-10.793 14.125,0.273 26.465,5.234 38.375,12.461 52.937,32.089 75.176,81.789 79.469,143.515 -29.641,-35.422 -71.555,-46.992 -111.325,-62.742 -7.152,-2.832 -13.582,-6.633 -19.261,-11.25 3.48,18.238 6.101,36.773 7.757,55.645 17.856,-14.836 36.059,-14.415 54.2,1.511 45.238,39.727 46.453,161.387 -1.618,207.989 -3.632,-48.196 -31.597,-83.536 -54.039,-122.051 -1.589,12.363 -3.777,24.609 -6.55,36.726 11.367,11.43 14.586,30.852 7,55.891 -12.93,42.715 -45.899,76.277 -51.758,128.691 -38.692,-58.152 -40.778,-113.515 -12.68,-189.043 -23.457,8.965 -30.801,35.196 -53.223,52.012 -8.679,-51.355 -8.668,-97.559 12.649,-141.797 16.82,-34.914 40.391,-45.437 63.246,-31.25 -3.273,-26.367 -8.238,-51.844 -14.68,-76.367 -1.273,6.719 -3.508,13.711 -6.855,20.793 -1.781,3.777 -3.582,7.742 -6.231,10.906 -24.554,29.426 -51.183,57.117 -75.3,95.43 -12.696,-52.969 -16.829,-99.063 1.711,-144.231 12.769,-31.101 40.89,-44.148 65.382,-33.687 2.379,1.023 4.532,2.293 6.543,3.711 -8.246,-20.899 -22.168,-48.086 -37.699,-76.231 -3.496,15.039 -11.434,28.946 -23.949,41.727 -19.094,19.473 -36.813,39.875 -48.418,65.105 -4.84,10.52 -13.934,20.344 -20.184,1.196 -15.851,-48.574 -25,-97.539 1.239,-145.989 14.164,-26.164 29.902,-37.441 49.593,-34.531 -9.406,-15.886 -18.066,-30.383 -25.007,-42.156 -7.2,-12.238 -15.278,-29.094 2.097,-39.367 18.129,-10.735 32.449,0.222 42.445,15.469 7.946,12.121 15.668,24.328 23.161,36.628 10.179,-16.421 29.945,-18.562 63.855,-8.355 76.449,23.016 118.797,79.98 147.859,160.508 -40.546,-21.313 -73.808,-38.879 -111.335,-45.539 -17.348,-3.086 -34.286,-7.215 -50.192,-13.567" /><path
                        id="path16"
                        d="m 493.355,904.988 c -222.746,0 -403.312,-180.562 -403.312,-403.308 0,-222.735 180.566,-403.3011 403.312,-403.3011 222.739,0 403.305,180.5661 403.305,403.3011 0,222.746 -180.566,403.308 -403.305,403.308 l 0,0 z m 0,-37.808 c 201.532,0 365.497,-163.957 365.497,-365.5 0,-201.532 -163.965,-365.496 -365.497,-365.496 -201.539,0 -365.5,163.964 -365.5,365.496 0,201.543 163.961,365.5 365.5,365.5" /></g></g>     </svg>
    );
};
