export const Routes = {
    home: '/',
    menu: '/menu',
    events: '/events',
    help: '/help',
    contact: '/contact',
    privacy: '/privacy',
    terms: '/terms',
    about: '/about',
    murder: '/events/murder',
    galery: '/gallery',
    vacatures: '/vacatures'

}